import React from 'react';
import AffiliateLink from './AffiliateLink';

function App() {
  return (
    <div>
      <AffiliateLink 
        href="https://tpmo.clinebell.com?npn=2218886" 
        imgSrc="https://example.com/your-image-url.jpg"
        altText="Click or Scan QRCode"
      />
    </div>
  );
}

export default App;
